/*
* お気に入り追加・削除
*/
$(function () {

  $.ajaxSetup({
    headers: {
      'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
  });

  /*
  * お気に入り追加・削除
  */
  $(document).on('change', '.shopFavourite', function(event) {
    let isMemberLogin = $(this).is('[member]');
    var elem = $(this);
    if (isMemberLogin) {
      // 店舗ID取得（属性にセットしておく）
      let shop_id = '';
      if ($(this).attr('shop_id')) {
        shop_id = $(this).attr('shop_id');
      }
      else {
        return false;
      }
      $.ajax({
        type: 'post',
        url: '/api/togglefavourite',
        data: {
          'shop_id': shop_id,
        },
        success: function (data) {
          // 成功
          if ($('.member-favorite-count').length) {
            // お気に入り数再表示
            Livewire.emitTo('member-favorite-count', 'refresh');
          }
          // お気に入りクラス設定
          elem.closest('.-follow').toggleClass('on');
        },
        error: function (XMLHttpRequest) {
          console.log(XMLHttpRequest);
        }
      });
    }
    else {
      //  未ログイン
    }

  });

});
